import React from 'react';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import HomePage from './pages/homePage/HomePage';
import CampaignDetailPage from './pages/campaignDetailPage/CampaignDetailPage';
import ProfilePage from './pages/profilePage/ProfilePage';
import BoxDetailPage from './pages/boxDetailPage/BoxDetailPage';
import { makeStyles } from '@mui/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1440px',
    margin: '0 auto',
    background: '#FAFBFC',
    // fontFamily: 'Segoe UI Variable Static Text',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
}));

function App() {
  const styles = useStyles();
  return (
    <Router>
      <div className={styles.root}>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Campaigns" element={<CampaignDetailPage />} />
          <Route path="/BoxDetail/:id" element={<BoxDetailPage />} />
          <Route path="/MyProfile" element={<ProfilePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
