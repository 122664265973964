import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, FormControl, MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import React from 'react';
import TreasureBox from '../../assets/images/TreasureBox.svg';
import AboutArtist from './AboutArtist';
import DropItem from './DropItem';
import SeriesContentTableData from './SeriesContentTableData';
import ModalCheckoutCampaign from './ModalCheckoutCampaign';
import ModalBuyBoxSPOSuccess from './ModalBuyBoxSPOSuccess';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FAFBFC',
    height: 'auto',
  },
  nav: {
    marginTop: '10px',
    minHeight: '870px',
    height: 'auto',
    background: '#181818',
  },
  navContainer: {
    padding: '68px  0px 0px 75px',
    display: 'flex',
  },
  //left
  left: {
    width: '562px',
    height: '754px',
    background: '#232323',
    padding: '30px',
    boxSizing: 'border-box',
  },
  detail: {
    width: '466px',
    height: '247px',
  },
  title: {
    fontSize: '20px;',
    lineHeight: '27px;',
    color: '#FFD362',
    margin: '0px 0px 10px 0px ',
  },
  textType: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '400',
  },
  table: {
    backgroundColor: '#2B2B2B',
    minWidth: '466px!important',
    borderRadius: '5px',
  },
  tableHead: {
    backgroundColor: '#393939',
    '& .MuiTableCell-root': {
      color: '#FFFFFF',
      fontSize: '14px',
      padding: '10px 20px',
      borderBottom: 'none',
    },
    '& .MuiTableCell-root:first-child': {
      paddingRight: '100px',
    },
  },
  tableTotal: {
    fontSize: '16px;',
    backgroundColor: '#2B2B2B',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    '& .MuiTableCell-root': {
      color: '#FFD362',
      fontSize: '14px',
      padding: '10px 20px',
    },
  },
  //right
  right: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: '1',
    textAlign: 'center',
  },
  rightWapper: {},
  headingTitle: {
    fontSize: '36px;',
    lineHeight: '48px; ',
    color: ' #FFFFFF;',
    margin: '0px 0px 10px 0px ',
  },
  rightContent: {
    width: '457px',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  remainingContainer: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  remainingText: {
    width: '257px',
    display: 'flex',
  },
  textStytle: {
    fontSize: '14px;',
    lineHeight: '16px; ',
    color: ' #FFFFFF;',
    opacity: 0.7,
  },
  loading: {
    width: '257px',
    height: '6px',
    backgroundColor: '#FFC700',
    borderRadius: '3px;',
    marginTop: '6px',
    marginBottom: '30px',
  },
  timeContainer: {
    width: '457px',
    minHeight: '56px',
    backgroundColor: '#242424',
    borderRadius: '8px;',
    justifyContent: 'center',
    display: 'flex',
    padding: '12px 30px',
    boxSizing: 'border-box',
  },
  timeTitle: {
    flexGrow: '1',
    fontSize: '14px;',
    lineHeight: '19px; ',
    color: ' #949496;',
    textAlign: 'start',
    alignSelf: 'center',
  },
  time: {
    fontSize: '25px;',
    lineHeight: '32px; ',
    color: ' #FF5630',
    textAlign: 'start',
    alignSelf: 'center',
  },
  contentContainer: {
    width: '457px',
    minHeight: '233px',
    backgroundColor: '#242424',
    borderRadius: '8px;',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
    padding: '12px 30px',
    boxSizing: 'border-box',
  },
  selectTitle: {
    fontSize: '14px;',
    lineHeight: '19px; ',
    color: ' #949496',
    textAlign: 'start',
    marginBottom: '4px',
  },
  dropbox: {
    '& .MuiOutlinedInput-root': {
      height: '32px',
      color: '#FFFFFF',
    },
    '& .MuiSelect-select': {
      textAlign: 'start',
    },
    '& .MuiOutlinedInput-notchedOutline,.MuiOutlinedInput-notchedOutline:hover': {
      borderColor: '#FFFFFF!important',
    },
    '& .MuiSvgIcon-root': {
      color: '#FFFFFF',
    },
  },
  contentTitle: {
    margin: '12px 0px 0px 0px',
    height: '30px',
    display: 'flex',
  },
  contentName: {
    flexGrow: '1',
  },
  contentValue: {
    color: '#FFFFFF',
    fontSize: '20px',
    lineHeight: '27px',
  },
  soldBtn: {
    backgroundColor: '#EBECF0 !important',
    width: '397px',
    height: '48px',
    boxShadow: '0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31) !important',
    color: '#C1C4D0!important',
    fontSize: '16px!important',
    marginTop: '12px!important',
  },
}));

function createData(name: string, Amount: number, TotalMinted: number, Probability: number) {
  return { name, Amount, TotalMinted, Probability };
}

const rows = [
  createData('Legendary', 1, 16, 1.03),
  createData('Epic', 1, 352, 0.59),
  createData('Super Rare', 1, 848, 1.41),
  createData('Rare', 3, 10800, 18),
  createData('Common', 10, 984, 79.97),
];

function CampaignDetailPage(props: any) {
  const styles = useStyles(props);
  const [price, setPrice] = React.useState('10');
  const handleChange = (event: SelectChangeEvent) => {
    setPrice(event.target.value as string);
  };
  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const handleOpenCloseCheckout = () => setOpenCheckout(!openCheckout);
  const handleOpenCloseSuccessModal = () => {
    setOpenCheckout(false);
    setOpenSuccessModal(!openSuccessModal);
  };
  return (
    <Box className={styles.root}>
      <Box className={styles.nav}>
        <Box className={styles.navContainer}>
          <Box className={styles.left}>
            <Box className={styles.detail}>
              <p className={styles.title}>Campaign detail</p>
              <span className={styles.textType}>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis
                enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation
                veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              </span>
            </Box>
            <Box>
              <p className={styles.title}>Series Content</p>
              <TableContainer component={Paper}>
                <Table className={styles.table} sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className={styles.tableHead}>
                      <TableCell>Rarity</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total Minted</TableCell>
                      <TableCell align="right">Probability</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <SeriesContentTableData
                        key={row.name}
                        row={row}
                        backgroundColor={'#2B2B2B'}
                        textColor={'#FFFFFF'}
                      />
                    ))}
                    <TableRow className={styles.tableTotal} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Total
                      </TableCell>
                      <TableCell align="right">16</TableCell>
                      <TableCell align="right">59,998</TableCell>
                      <TableCell align="right">100%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box className={styles.right}>
            <Box className={styles.rightWapper}>
              <p className={styles.headingTitle}>Bored Ape Yacht Club - Wave #1</p>
              <Box className={styles.rightContent}>
                <Box className={styles.remainingContainer}>
                  <Box className={styles.remainingText}>
                    <span className={styles.textStytle} style={{ flexGrow: '1', textAlign: 'start' }}>
                      Remaining
                    </span>
                    <span className={styles.textStytle}>50,000 / 50,000</span>
                  </Box>
                  <Box className={styles.loading}></Box>
                </Box>
                <img
                  style={{ width: '294px', height: '322px', margin: '0 auto', marginBottom: '15px' }}
                  src={`${TreasureBox}`}
                  alt=""
                />
                <Box className={styles.right}>
                  <Box className={styles.timeContainer}>
                    <span className={styles.timeTitle}>Campaign ends in</span>
                    <span className={styles.time}>00 : 00 : 00 : 00</span>
                  </Box>
                </Box>
                <Box className={styles.contentContainer}>
                  <span className={styles.selectTitle}>Select Currency</span>
                  <FormControl fullWidth className={styles.dropbox}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={price}
                      onChange={handleChange}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value={10}>SPO</MenuItem>
                      <MenuItem value={20}>USD</MenuItem>
                      <MenuItem value={30}>VND</MenuItem>
                    </Select>
                  </FormControl>
                  <Box className={styles.contentTitle}>
                    <span className={styles.selectTitle + ' ' + styles.contentName}>Remaining</span>
                    <span className={styles.contentValue}>10,000 boxes</span>
                  </Box>
                  <Box className={styles.contentTitle}>
                    <span className={styles.selectTitle + ' ' + styles.contentName}>Price per box</span>
                    <span className={styles.contentValue}>30 SPO</span>
                  </Box>
                  <Button className={styles.soldBtn} variant="contained" onClick={handleOpenCloseCheckout}>
                    SOLD OUT
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <DropItem />
      <AboutArtist />
      <ModalCheckoutCampaign
        open={openCheckout}
        handleOpenClose={handleOpenCloseCheckout}
        handleOpenCloseSuccessModal={handleOpenCloseSuccessModal}
      />
      <ModalBuyBoxSPOSuccess open={openSuccessModal} handleOpenClose={handleOpenCloseSuccessModal} />
    </Box>
  );
}

export default CampaignDetailPage;
