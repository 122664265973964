import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import Monkey from '../../assets/fakeImages/monkey.svg';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px 120px 88px 120px',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px;',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  content1: {
    width: '488px',
    height: '436px',
    borderRadius: '8px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05)',
    padding: '20px 40px',
    boxSizing: 'border-box',
  },
  title: {
    lineHeight: '24px',
    margin: '0 0 20px 0',
    fontWeight: '470',
  },
  author: {
    lineHeight: '24px',
    margin: '0 0 8px 0',
    fontWeight: '470',
  },
  paragraph: {
    lineHeight: '20px;',
    color: '#767676',
    margin: '0 ',
    overflowY: 'auto',
    height: '296px',
    paddingRight: '15px',
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px;',
      border: '1px solid #E2E8F0;',
      backgroundColor: '#E2E8F0;',
      borderRadius: '100px;',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      background: '#000;',
      borderRadius: '10px;',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4A5568;',
      height: '40px;',
      borderRadius: '100px;',
    },
  },
  content2: {
    width: '692px',
    height: '436px',
    borderRadius: '8px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05)',
    padding: '20px 40px',
    boxSizing: 'border-box',
  },
  typeBtnWapper: {
    padding: '0px !important',
    borderRadius: '50px!important',
    '&.Mui-selected': {
      '& span:first-child': {
        color: ' #FFFFFF',
        backgroundColor: '#201934',
      },
    },
  },
  typeBtn: {
    background: '#FFFFFF',
    border: '1px solid #C1C4D0',
    boxSizing: 'border-box',
    borderRadius: '50px',
    height: '40px',
    lineHeight: '23px',
    fontSize: '14px',
    padding: '8px 16px',
    textTransform: 'none',
  },
  body: {
    padding: '0px!important',
    marginTop: '16px',
    flexDirection: 'column',
    overflowY: 'auto',
    height: '320px',
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px;',
      border: '1px solid #E2E8F0;',
      backgroundColor: '#E2E8F0;',
      borderRadius: '100px;',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      background: '#000;',
      borderRadius: '10px;',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4A5568;',
      height: '40px;',
      borderRadius: '100px;',
    },
  },
  wrapper: {
    height: '61px',
    display: 'flex',
    margin: '10px 0px',
  },
  name: {
    fontSize: ' 16px;',
    lineHeight: '21px',
    color: '#242424',
    margin: '0 ',
  },
  version: {
    fontSize: ' 12px;',
    lineHeight: '16px',
    color: '#949496',
    margin: '0 ',
  },
}));

function CardPublicContentBottom(props: any) {
  const styles = useStyles(props);
  const [value, setValue] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  return (
    <Box className={styles.container}>
      <Box className={styles.content1}>
        <p className={styles.title}>About artist</p>
        <p className={styles.author}>Create by @artist name</p>
        <Box className={styles.paragraph}>
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim
          velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit
          aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt
          nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet Amet minim mollit non
          deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
          Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor
          do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
        </Box>
      </Box>
      <Box className={styles.content2}>
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab className={styles.typeBtnWapper} label={<span className={styles.typeBtn}>Owners</span>} value="1" />
              <Tab className={styles.typeBtnWapper} label={<span className={styles.typeBtn}>History</span>} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" className={styles.body}>
            {arr.map((item: any) => (
              <Box key={item} className={styles.wrapper}>
                <Box
                  style={{
                    border: '1px solid #E3E3E3',
                    borderRadius: '50%',
                    height: '42px',
                    width: '42px',
                    boxSizing: 'border-box',
                    marginRight: '10px',
                  }}
                >
                  <Avatar
                    style={{ border: '1px solid #ffffff', borderRadius: '50%', boxSizing: 'border-box' }}
                    alt="Remy Sharp"
                    src={`${Monkey}`}
                  />
                </Box>
                <Box>
                  <p className={styles.name}>User name</p>
                  <p className={styles.version}>6 editions</p>
                </Box>
              </Box>
            ))}
          </TabPanel>
          <TabPanel value="2" className={styles.body}>
            Item Two
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default CardPublicContentBottom;
