import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Checkbox, Grid, InputBase } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Card from '../../components/card/Card';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '60px 32px ',
    display: 'flex',
    height: 'auto',
    minHeight: '1680px',
  },
  //left
  left: {
    width: '308px;',
    height: 'auto;',
    display: 'flex',
    flexDirection: 'column',
  },
  marketLootBoxTitle: {
    color: '#17204D',
    fontSize: '29px',
    lineHeight: '40px',
    marginTop: '0px',
  },
  marketLootBox: {
    minHeight: '550px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05)',
    borderRadius: '8px',
    flexGrow: '1',
  },
  //filter
  textFilter: {
    paddingTop: '20px',
    fontSize: '20px;',
    lineHeight: '28px',
    margin: '0px 20px',
    color: '#17204D;',
  },
  filterContainer: {
    margin: '20px 20px 0px 20px',
    minHeight: '110px',
  },
  typeBtn: {
    width: '268px;',
    height: '38px;',
    justifyContent: 'flex-start !important',
    borderBottom: '1px solid #e6e6e6 !important',
    position: 'relative',
    color: '#42496E !important',
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: '0px',
    },
  },
  typeCheckbox: {
    borderRadius: '2px!important',
    padding: '8px 0px 8px 0px !important',
    color: '#CBD5E0 !important',
    '&.Mui-checked': {
      color: '#CFB675!important',
    },
  },
  //campaigns
  campaignContainer: {
    margin: '36px 20px 0px 20px',
    minHeight: '280px',
  },
  campaignContent: {
    marginTop: '12px',
    height: '200px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px;',
      border: '1px solid #E2E8F0;',
      backgroundColor: '#E2E8F0;',
      borderRadius: '100px;',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      background: '#000;',
      borderRadius: '10px;',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4A5568;',
      height: '40px;',
      borderRadius: '100px;',
    },
  },
  checkBoxValue: {
    float: 'right',
    lineHeight: '35px',
    marginRight: '10px',
  },
  //price
  priceContainer: {
    margin: '32px 20px 0px 20px',
    minHeight: '320px',
  },
  minMaxContainer: {
    display: 'flex',
    height: '40px',
    marginTop: '12px',
  },
  priceMinMax: {
    display: 'flex',
  },
  containerMinMax: {
    height: '100%',
    width: '115.5px',
  },
  textTo: {
    margin: '0px 12px ',
    lineHeight: '40px',
    fontSize: '12px',
    color: '#7A7F9A',
  },

  //right
  right: {
    width: '1028px;',
    height: 'auto;',
    marginLeft: '40px',
  },
  //tags
  tagContainer: {
    display: 'flex',
  },
  tag: {
    height: '36px',
    width: 'auto',
    background: '#FFFFFF',
    border: '1px solid #C1C4D0',
    boxSizing: 'border-box',
    borderRadius: '50px',
    padding: '6px 12px;',
    marginRight: '8px',
  },
  closeIcon: {
    fontSize: '25px',
    marginLeft: '8px;',
    cursor: 'pointer;',
  },
  clearBtn: {
    color: '#5243AA!important',
    fontSize: '14px',
  },
  dropdownContainer: {
    background: ' #FFFFFF',
    border: '1px solid #C1C4D0',
    boxSizing: 'border-box',
    borderRadius: '24px',
    width: '250px',
    display: 'flex',
  },
  btnCurrency: {
    margin: '0px ',
  },
  selectDropdown: {
    height: '40px',
    lineHeight: '40px',
    fontSize: '14px!important',
    '& .MuiOutlinedInput-root': {
      color: '#42526E !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .demo-simple-select-label': {
      padding: '8px',
    },
    '& #demo-simple-select-label': {
      top: '-6px',
    },
    '& .MuiSelect-select': {
      padding: '8px',
    },
  },
  //card
  cardContainer: {},
  card: {
    marginRight: '20px',
  },
  pagination: {
    display: 'flex',
    '& .Mui-selected': {
      backgroundColor: '#252E58!important',
      color: '#fff',
      borderRadius: '3px;',
    },
  },
  customGrid: {
    padding: '0px!important',
    margin: '0px 0px 16px 0px!important',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  border: '2px solid #CBD5E0',
  boxSizing: 'border-box',
  borderEndEndRadius: '4px',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    color: '#C1C4D0',
    fontWeight: '400',
  },
}));

const MinMaxInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '115.5px',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function LootBoxMarket(props: any) {
  const styles = useStyles(props);
  const [isType, setIsType] = React.useState(true);
  const [isCampaign, setIsCampaign] = React.useState(true);
  const [isPrice, setIsPrice] = React.useState(true);
  const [price, setPrice] = React.useState('10');
  const iconType = isType ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  const iconCampaign = isCampaign ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  const iconPrice = isPrice ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  const handleChange = (event: SelectChangeEvent) => {
    setPrice(event.target.value as string);
  };
  const handleClickType = () => {
    setIsType(!isType);
  };
  const handleClickCampaign = () => {
    setIsCampaign(!isCampaign);
  };
  const handleClickPrice = () => {
    setIsPrice(!isPrice);
  };

  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const [checkboxList, setCheckboxList] = React.useState<string[]>(['All']);

  const handleChangeCheckbox = (event: any, isInputChecked: boolean) => {
    let arrCatagory = [...checkboxList];
    console.log(event.target.value, isInputChecked);
    if (isInputChecked) {
      setCheckboxList([...arrCatagory, event.target.value]);
    } else {
      arrCatagory = arrCatagory.filter(item => item !== event.target.value);
      setCheckboxList([...arrCatagory]);
    }
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.left}>
        <p className={styles.marketLootBoxTitle}>LOOT BOX MARKET</p>
        <Box className={styles.marketLootBox}>
          <p className={styles.textFilter}>Filter</p>
          <Box className={styles.filterContainer}>
            <Button
              className={styles.typeBtn}
              style={{ textTransform: 'inherit' }}
              variant="text"
              endIcon={iconType}
              onClick={handleClickType}
            >
              Type
            </Button>
            {isType && (
              <Box style={{ marginTop: '14px' }}>
                <Grid container spacing={2}>
                  <Grid style={{ paddingTop: '0px' }} item xs={6} md={6}>
                    <FormControlLabel
                      style={{ margin: '0px' }}
                      label="All"
                      control={
                        <Checkbox
                          className={styles.typeCheckbox}
                          value="All"
                          onChange={handleChangeCheckbox}
                          defaultChecked
                        />
                      }
                    />
                  </Grid>
                  <Grid style={{ paddingTop: '0px' }} item xs={6} md={6}>
                    <FormControlLabel
                      style={{ margin: '0px' }}
                      label="Boxes"
                      control={
                        <Checkbox className={styles.typeCheckbox} value="Boxes" onChange={handleChangeCheckbox} />
                      }
                    />
                  </Grid>
                  <Grid style={{ paddingTop: '0px' }} item xs={6} md={6}>
                    <FormControlLabel
                      style={{ margin: '0px' }}
                      label="Items"
                      control={
                        <Checkbox className={styles.typeCheckbox} value="Items" onChange={handleChangeCheckbox} />
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <Box className={styles.campaignContainer}>
            <Button
              className={styles.typeBtn}
              style={{ textTransform: 'inherit' }}
              variant="text"
              endIcon={iconCampaign}
              onClick={handleClickCampaign}
            >
              Campaign
            </Button>
            {isCampaign && (
              <Box style={{ marginTop: '14px' }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase placeholder="Search" inputProps={{ 'aria-label': 'search' }} />
                </Search>
                <Box className={styles.campaignContent}>
                  {arr.map((item: any) => (
                    <Box key={item} style={{ fontSize: '14px' }}>
                      <FormControlLabel
                        style={{ margin: '0px' }}
                        label="Bored Ape Yacht Club"
                        control={
                          <Checkbox
                            className={styles.typeCheckbox}
                            value="Bored Ape Yacht Club"
                            onChange={handleChangeCheckbox}
                          />
                        }
                      />
                      <span className={styles.checkBoxValue}>100</span>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          <Box className={styles.priceContainer}>
            <Button
              className={styles.typeBtn}
              style={{ textTransform: 'inherit' }}
              variant="text"
              endIcon={iconPrice}
              onClick={handleClickPrice}
            >
              Price
            </Button>
            {isPrice && (
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={price}
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={10}>USDC</MenuItem>
                    <MenuItem value={20}>USD</MenuItem>
                    <MenuItem value={30}>VND</MenuItem>
                  </Select>
                </FormControl>
                <Box className={styles.minMaxContainer}>
                  <Box className={styles.priceMinMax}>
                    <FormControl className={styles.containerMinMax} variant="standard">
                      <MinMaxInput placeholder="Min" id="bootstrap-input" />
                    </FormControl>
                  </Box>
                  <span className={styles.textTo}>To</span>
                  <Box className={styles.priceMinMax}>
                    <FormControl className={styles.containerMinMax} variant="standard">
                      <MinMaxInput placeholder="Max" id="bootstrap-input" />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box className={styles.right}>
        <Box className={styles.tagContainer}>
          <Box style={{ flexGrow: '1' }}>
            <Grid className={styles.customGrid} container spacing={2}>
              {checkboxList.map((item: any, index) => (
                <Grid className={styles.customGrid} key={index}>
                  <span className={styles.tag}>
                    {item}
                    <span className={styles.closeIcon}>&times;</span>
                  </span>
                </Grid>
              ))}
              <Button className={styles.clearBtn} style={{ textTransform: 'inherit' }} variant="text">
                Clear all
              </Button>
            </Grid>
          </Box>
          <Box>
            <Box className={styles.dropdownContainer} style={{ display: 'flex' }}>
              <Box className={styles.selectDropdown} style={{ minWidth: '105px' }}>
                <FormControl fullWidth style={{ border: 'none' }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={price}
                    label="Age"
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={10}>Currency</MenuItem>
                    <MenuItem value={20}>AAA</MenuItem>
                    <MenuItem value={30}>VVV</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className={styles.selectDropdown} style={{ flexGrow: '1' }}>
                <FormControl fullWidth style={{ border: 'none' }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={price}
                    label="Age"
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={10}>Recently added</MenuItem>
                    <MenuItem value={20}>AAA</MenuItem>
                    <MenuItem value={30}>VVV</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.cardContainer}>
          <Grid className={styles.customGrid} container spacing={2}>
            {arr.map((item: any, index) => (
              <Grid className={styles.customGrid} key={item} item xs={12} md={6} lg={4} xl={3}>
                <Card type={'Legendary'} id={index} />
              </Grid>
            ))}
          </Grid>
          <Box className={styles.pagination}>
            <Stack style={{ margin: '24px auto 0px' }} spacing={2}>
              <Pagination count={10} shape="rounded" />
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LootBoxMarket;
