import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Slider from 'react-slick';
import Monkey from '../../assets/fakeImages/monkey.svg';

const useStyles = makeStyles(theme => ({
  container: {
    height: '80vh',
    maxHeight: '854px',
    maxWidth: '1280px',
    width: '80vw',
    backgroundColor: '#ffffff',
    position: 'relative',
    '&:focus-visible': {
      outline: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px;',
  },
  closeBtn: {
    position: 'absolute',
    right: '-30px',
    top: '-55px',
    fontSize: '40px',
    color: '#949496',
  },
  wrapper: {
    margin: '30px 40px 60px 40px',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    display: 'flex',
  },
  left: {
    minWidth: '690px;',
    height: '510px;',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F9F9F9;',
    '& img': {
      width: '490px',
      height: '450px',
      cursor: 'pointer',
    },
  },
  right: {
    padding: '30px 0px 0px 30px',
    flexGrow: '1',
    '& p': {
      margin: '0px 0px 16px 0px',
    },
  },
  rightTitle: {
    margin: '0px 0px 19px 0px',
    fontSize: '32px;',
    lineHeight: '44px;',
  },
  key: {
    color: '#767676',
    fontSize: '14px;',
    lineHeight: '19px;',
  },
  typeCard: {
    border: (props: any) =>
      props.type.toLowerCase() === 'legendary'
        ? '1px solid #D66B00'
        : props.type.toLowerCase() === 'epic'
        ? '1px solid #CC00D0'
        : props.type.toLowerCase() === 'rare'
        ? '1px solid #00DD3F'
        : props.type.toLowerCase() === 'super rare'
        ? '1px solid #61C4F7'
        : '1px solid',
    borderRadius: '18px;',
    padding: '2px 10px;',
    color: (props: any) =>
      props.type.toLowerCase() === 'legendary'
        ? '#D66B00'
        : props.type.toLowerCase() === 'epic'
        ? '#CC00D0'
        : props.type.toLowerCase() === 'rare'
        ? '#00DD3F'
        : props.type.toLowerCase() === 'super rare'
        ? '#61C4F7'
        : '',
    boxSizing: 'border-box',
    fontSize: '12px',
  },
  desTitle: {
    fontSize: '16px;',
    margin: '13px 0px 10px 0px',
    color: '#242424',
  },
  bottom: {
    flexGrow: '1',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
  },
  slider: {
    zIndex: '1000',
    width: '100%',
    height: '155px',
    position: 'relative',
    '& .slick-list': {
      overflow: 'hidden',
      position: 'absolute',
      width: '100%',
      height: '100%;',
      zIndex: '1',
    },
    '& .slick-list .slick-track': {
      // position: 'absolute',
      width: '100%',
      // width: '100vh!important',
      height: '100%',
      display: 'flex',
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        height: '4px',
        width: '4px;',
        border: '1px solid #E2E8F0;',
        backgroundColor: '#E2E8F0;',
        borderRadius: '100px;',
      },
      '&::-webkit-scrollbar-thumb:horizontal': {
        background: '#000;',
        borderRadius: '10px;',
        // width: '100vw;',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#4A5568;',
        height: '40px;',
        borderRadius: '100px;',
      },
    },
    '& .slick-prev': {
      position: 'absolute',
      backgroundColor: 'rgba(77, 77, 77, 0.8)',
      top: '30%',
      left: '0px',
      zIndex: '1000',
      display: 'flex!important',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
    },
    '& .slick-next': {
      position: 'absolute',
      top: '30%',
      right: '0px',
      zIndex: '1000',
      backgroundColor: 'rgba(77, 77, 77, 0.8)',
      display: 'flex!important',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
    },
    '& .slick-slide': {
      marginRight: '10px',
    },
  },
  slickImage: {
    height: '140px',
    width: '165px',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  titleSlider: {
    fontSize: '20px;',
    color: '#242424;',
  },
  wrapImg: {
    '&:focus-visible': {
      outline: 'none',
    },
  },
  viewImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  imgContainer: {
    width: '873.71px',
    height: '800px',
    position: 'relative',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  closeBtnImg: {
    position: 'absolute',
    right: '-50px',
    top: '0px',
    fontSize: '40px',
    color: '#FFFFFF',
    background: '#4B4B4B',
  },
}));

function CardDetailModel(props: { open: boolean; handleOpenClose: () => void; type: string }) {
  const styles = useStyles(props);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    draggable: true,
    nextArrow: (
      <Box>
        <ArrowForwardIosIcon style={{ color: '#FFFFFF' }} />
      </Box>
    ),
    prevArrow: (
      <Box>
        <ArrowBackIosNewIcon style={{ color: '#FFFFFF' }} />
      </Box>
    ),
  };
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const [showImg, setShowImg] = React.useState(false);
  const handleOpenCloseImg = () => {
    setShowImg(!showImg);
    props.handleOpenClose();
  };
  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box className={styles.container}>
          <span className={styles.closeBtn} onClick={props.handleOpenClose}>
            &times;
          </span>
          <Box className={styles.wrapper}>
            <Box className={styles.top}>
              <Box className={styles.left}>
                <img src={`${Monkey}`} alt="" onClick={handleOpenCloseImg} />
              </Box>
              <Box className={styles.right}>
                <p className={styles.rightTitle}>Ceremony of Ethereum 2.0</p>
                <p>
                  <span className={styles.key}>Campaign: </span>
                  <span style={{ color: '#0f81f3' }}>Fara Sporesian Loot Box</span>
                </p>
                <p>
                  <span className={styles.key}>Artist:</span> Mike Mular
                </p>
                <p>
                  <span className={styles.key}>Rarity:</span> <span className={styles.typeCard}>{props.type}</span>
                </p>
                <p>
                  <span className={styles.key}>Amount: </span>1,000
                </p>
                <p>
                  <span className={styles.key}>Probability: </span>10%
                </p>
                <p className={styles.desTitle}>Description</p>
                <p className={styles.key}>
                  These animated postcards feature stunning vistas from four different locations in Big Time: Time’s End
                  - the last planet at the end of the universe. The Syphon - where ancient technology draws power from a
                  collapsing star. The Paradox
                </p>
              </Box>
            </Box>
            <Box className={styles.bottom}>
              <p className={styles.titleSlider}>Within this Campaign</p>
              <Slider className={styles.slider} {...settings}>
                {arr.map((item: any) => (
                  <div key={item} className={styles.wrapImg}>
                    <img className={styles.slickImage} src={`${Monkey}`} alt="" onClick={() => console.log(item)} />
                  </div>
                ))}
              </Slider>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showImg}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box className={styles.imgContainer}>
          <CloseIcon className={styles.closeBtnImg} onClick={handleOpenCloseImg} />
          <img className={styles.viewImage} src={`${Monkey}`} alt="" />
        </Box>
      </Modal>
    </>
  );
}

export default CardDetailModel;
