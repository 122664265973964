import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import { ReactComponent as RectangleLeft } from '../../assets/images/RectangleLeft.svg';
import { ReactComponent as RectangleRight } from '../../assets/images/RectangleRight.svg';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import Monkey from '../../assets/fakeImages/fakebackground.svg';

const useStyles = makeStyles(theme => ({
  nav: {
    height: '690px',
    background: '#201934;',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navContainer: {
    width: 'auto',
    display: 'flex',
    margin: '0px 32px',
    position: 'absolute',
    zIndex: '2000',
  },
  section1: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  section2: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section3: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  title: {
    fontSize: '48px;',
    lineHeight: '60px;',
    letterSpacing: '3px;',
    color: '#FFFFFF',
    textShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.4)',
    margin: '0px 0px 0px 0px',
    textTransform: 'uppercase',
  },
  artistname: {
    margin: '0px 0px 0px 0px',
    textTransform: 'uppercase',
    opacity: '0.5',
    color: '#FFFFFF',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.4)',
    fontSize: '16px;',
    lineHeight: '24px;',
    letterSpacing: '2px;',
  },
  total: {
    margin: '20px 0px 36px 0px',
    textTransform: 'uppercase',
    opacity: '0.6',
    color: '#FFFFFF',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.4)',
    fontSize: '16px;',
    lineHeight: '24px;',
    letterSpacing: '2px;',
  },
  btnSeeMore: {
    padding: '8px 16px;',
    background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
    borderRadius: '4px',
    color: '#17204D!important',
    width: '160px',
    height: '40px',
  },
  clock: {
    width: '393px',
    height: '160px',
    background: 'linear-gradient(154.49deg, rgba(124, 124, 141, 0.48) 5.35%, rgba(102, 102, 126, 0.18) 83.85%)',
    backdropFilter: 'blur(24px)',
    borderRadius: '8px;',
    marginBottom: '20px',
  },
  clockContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textStartIn: {
    lineHeight: '24px;',
    letterSpacing: '2px;',
    color: '#FFFFFF',
  },
  timeContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  timeBox: {
    background: ' #FFFFFF',
    borderRadius: '8px',
    width: '59px',
    height: '50px',
    margin: ' 0px 10px;',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#17204D',
    fontSize: '24px',
    fontWeight: '600',
    fontFamily: 'IBM Plex Sans',
  },
  splitDot: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '31px',
    textAlign: 'center',
    color: '#FFFFFF',
    opacity: '0.3',
    padding: '5px 0px;',
  },
  timeDate: {
    color: '#FFFFFF',
    opacity: '0.5',
    fontSize: '12px',
    lineHeight: '16px',
    paddingRight: '58px',
    '&:last-child': {
      paddingRight: '0px',
    },
  },
  slider: {
    zIndex: '1000',
    width: '100%',
    height: '100%',
    position: 'relative',
    '& .slick-list': {
      overflow: 'hidden',
      position: 'absolute',
      width: '100%',
      height: '100%;',
      zIndex: '1',
    },
    '& .slick-list .slick-track': {
      // position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    '& .slick-prev': {
      position: 'absolute',
      top: '50%',
      left: '32px',
      zIndex: '1000',
    },
    '& .slick-next': {
      position: 'absolute',
      top: '50%',
      right: '32px',
      zIndex: '1000',
    },
  },
}));

function SlickBackground(props: any) {
  const styles = useStyles(props);
  const navigate = useNavigate();

  const renderer = ({ hours, minutes, seconds, days }: any) => {
    return (
      <Box className={styles.timeContainer}>
        <Box>
          <Box className={styles.timeBox}>{`${days}`}</Box>
          <span className={styles.timeDate}>Days</span>
        </Box>
        <span className={styles.splitDot}>:</span>
        <Box>
          <Box className={styles.timeBox}>{`${hours}`}</Box>
          <span className={styles.timeDate}>Hours</span>
        </Box>
        <span className={styles.splitDot}>:</span>
        <Box>
          <Box className={styles.timeBox}>{`${minutes}`}</Box>
          <span className={styles.timeDate}>Minutes</span>
        </Box>
        <span className={styles.splitDot}>:</span>
        <Box>
          <Box className={styles.timeBox}>{`${seconds}`}</Box>
          <span className={styles.timeDate}>Seconds</span>
        </Box>
      </Box>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <Box className={styles.section3}>
        <RectangleRight />
      </Box>
    ),
    prevArrow: (
      <Box className={styles.section1}>
        <RectangleLeft />
      </Box>
    ),
  };

  return (
    <Box className={styles.nav}>
      <Slider className={styles.slider} {...settings}>
        <div>
          <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${Monkey}`} alt="" />
        </div>
        <div>
          <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${Monkey}`} alt="" />
        </div>
        <div>
          <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${Monkey}`} alt="" />
        </div>
        <div>
          <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${Monkey}`} alt="" />
        </div>
      </Slider>
      <Box className={styles.navContainer}>
        <Box className={styles.section2}>
          <p className={styles.artistname}>BY ARTIST NAME</p>
          <p className={styles.title}>Bored Ape Yacht Club - Wave #1</p>
          <p className={styles.total}>Total: 1,996 Boxes</p>
          <Box className={styles.clock}>
            <Box className={styles.clockContainer}>
              <p className={styles.textStartIn}>CAMPAIGN STARTS IN</p>
              <Countdown date={Date.now() + 500000000} renderer={renderer} />
            </Box>
          </Box>
          <Button
            variant="contained"
            style={{ textTransform: 'inherit' }}
            className={styles.btnSeeMore}
            onClick={() => navigate('/Campaigns')}
          >
            See more
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default SlickBackground;
