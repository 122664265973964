import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Loading from '../../../components/Loading';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '10px 10px 10px 10px',
    boxSizing: 'border-box',
  },
  wapImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '260px',
    height: '268px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    marginBottom: '21px',
  },
  wapContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    width: '217px;',
    height: '21px',
    background: ' #F6F6F6;',
    borderRadius: '11px',
  },
  ava: {
    width: '33px;',
    height: '33px',
    background: ' #F6F6F6;',
    borderRadius: '50%',
  },
}));

function OpenBoxLoading(props: any) {
  const styles = useStyles(props);
  return (
    <Box className={styles.container}>
      <Box className={styles.wapImg}>
        <Loading color="#CE9911" width={36} height={36} />
      </Box>
      <Box className={styles.wapContent}>
        <Box className={styles.text}></Box>
        <Box className={styles.ava}></Box>
      </Box>
    </Box>
  );
}

export default OpenBoxLoading;
