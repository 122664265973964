import { Box, Modal } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Monkey from '../../../assets/fakeImages/monkey.svg';
import OpenBoxLoading from './OpenBoxLoading';
import {
  getBorderColorTypeCard,
  getColorTypeCard,
  getBoxShadowColorTypeCard,
  getBoxShadowBox1ColorTypeCard,
  getBoxShadowBox2ColorTypeCard,
  getBackgroundBox3ColorTypeCard,
  getBackgroundModalColorTypeCard,
  getBackgroundContainerColorTypeCard,
} from '../../../utilities/helper';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    top: '250px!important',
    '& .MuiBackdrop-root': {
      background: (props: { type: string }) => getBackgroundModalColorTypeCard(props.type),
      backdropFilter: 'blur(24px)',
    },
  },
  modalLoading: {
    display: 'flex',
    justifyContent: 'center',
    top: '250px!important',
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.7);',
      backdropFilter: 'blur(24px)',
    },
  },
  containerLoading: {
    height: '346px',
    width: '280px',
    maxHeight: '854px',
    maxWidth: '1280px',
    backgroundColor: '#ffffff',
    position: 'relative',
    '&:focus-visible': {
      outline: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px;',
    boxShadow: '0px 1px 10px rgba(122, 122, 122, 0.1)',
    boxSizing: 'border-box',
    border: '1px solid #DDE3F2',
  },
  container: {
    height: '349px',
    width: '308px',
    maxHeight: '854px',
    maxWidth: '1280px',
    background: (props: { type: string }) => getBackgroundContainerColorTypeCard(props.type),
    position: 'relative',
    '&:focus-visible': {
      outline: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: (props: { type: string }) => getBoxShadowColorTypeCard(props.type),
    boxSizing: 'border-box',
    borderRadius: '20px',
  },
  body: {
    height: '320px',
    width: '280px',
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 10px rgba(122, 122, 122, 0.1)',
    borderRadius: '4px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: ' 1px solid #DDE3F2',
    position: 'absolute',
    yop: '0px',
  },
  cardImg: {
    height: '270px',
    width: '262px',
    objectFit: 'cover',
  },
  wapBtn: {
    display: 'flex',
    position: 'absolute',
    bottom: '-125px',
  },
}));

const Box1 = styled(Box)((props: { type: string }) => ({
  boxShadow: getBoxShadowBox1ColorTypeCard(props.type),
  filter: 'blur(3px)',
  borderRadius: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '349px',
  width: '308px',
}));

const Box2 = styled(Box1)((props: { type: string }) => ({
  boxShadow: getBoxShadowBox2ColorTypeCard(props.type),
  height: '338px',
  width: '298px',
}));

const Box3 = styled(Box1)((props: { type: string }) => ({
  height: '340px',
  width: '290px',
  background: getBackgroundBox3ColorTypeCard(props.type),
}));

const NameCard = styled(Box)(({ theme }) => ({
  fontSize: '25px',
  lineHeight: '32px',
  color: '#FFFFFF',
  textAlign: 'center',
  position: 'absolute',
  bottom: '-65px',
}));

const ColorButtonFFCA42 = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#242424',
  minWidth: '210px',
  minHeight: '41px',
  fontSize: '16px',
  background: '#FFCA42',
  '&:hover': {
    background: '#FFCA42',
  },
  fontWeight: '400',
  borderRadius: '4px',
  boxShadow: '0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31)',
  height: '41px',
  fontFamily: 'inherit',
  textTransform: 'none',
  marginRight: '20px',
}));

const ColorButtonFFFFFF = styled(ColorButtonFFCA42)<ButtonProps>(({ theme }) => ({
  background: 'none',
  '&:hover': {
    background: 'none',
  },
  border: '1px solid #FFFFFF',
  color: '#FFFFFF',
}));

const TypeCard = styled('span')((props: { type: string }) => ({
  border: getBorderColorTypeCard(props.type),
  color: getColorTypeCard(props.type),
  borderRadius: '18px',
  padding: '2px 10px;',
  boxSizing: 'border-box',
  fontSize: '14px',
  marginTop: '8px',
}));

function ModalBoxOpened(props: any) {
  let type = 'super rare';
  const styles = useStyles({ type: type, ...props });
  let isloading = false;

  return (
    <Modal
      open={props.open}
      onClose={props.handleOpenCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={isloading ? styles.modalLoading : styles.modal}
    >
      <Box className={isloading ? styles.containerLoading : styles.container}>
        {isloading ? (
          <OpenBoxLoading />
        ) : (
          <>
            <Box1 type={type}>
              <Box2 type={type}>
                <Box3 type={type}></Box3>
              </Box2>
            </Box1>
            <Box className={styles.body}>
              <img className={styles.cardImg} src={`${Monkey}`} alt="" />
              <TypeCard type={type}>Legendary</TypeCard>
            </Box>
            <NameCard>Cryptomon #432</NameCard>
            <Box className={styles.wapBtn}>
              <ColorButtonFFFFFF>Go to Profile</ColorButtonFFFFFF>
              <ColorButtonFFCA42>View details</ColorButtonFFCA42>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default ModalBoxOpened;
