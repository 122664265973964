import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import Divider from '@mui/material/Divider';
import Monkey from '../../../assets/fakeImages/monkey.svg';

const useStyles = makeStyles(theme => ({
  container: {
    height: '503px',
    maxHeight: '854px',
    maxWidth: '1280px',
    width: '568px',
    backgroundColor: '#ffffff',
    position: 'relative',
    '&:focus-visible': {
      outline: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px;',
  },
  header: {
    position: 'relative',
    textAlign: 'center',
    height: '65px',
    lineHeight: '65px',
    fontSize: '25px',
    background: '#F9F9F9',
    color: ' #242424',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  closeIcon: {
    position: 'absolute',
    top: '20px',
    right: '65px',
    cursor: 'pointer',
  },
  content: {
    width: '448px',
    height: '334px',
    margin: '0 auto',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F9F9F9',
    flexGrow: '1',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  row: {
    display: 'flex',
    margin: '10px 0',
  },
  rowTitle: {
    flexGrow: '1',
  },
  img: {
    width: '70px',
    height: '70px',
    objectFit: 'cover',
    marginRight: '10px',
  },
  boxName: {
    color: '#CFB675',
  },
  value: {
    fontSize: '14px',
  },
  capcha: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '39px',
  },
}));

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#17204D',
  background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
  '&:hover': {
    background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
  },
  boxShadow: '0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31)',
  borderRadius: '4px',
  width: '207px',
  height: '48px',
}));

function ModalCheckout(props: { open: boolean; handleOpenCloseModal: () => void; handleClick: () => void }) {
  const styles = useStyles(props);
  const [isRobot, setIsRobot] = useState(true);
  function onChange(value: any) {
    setIsRobot(!isRobot);
  }
  const handleCloseCheckout = () => {
    props.handleOpenCloseModal();
    setIsRobot(true);
  };
  const handleCheckout = () => {
    props.handleClick();
    setIsRobot(true);
  };

  return (
    <Modal
      open={props.open}
      // onClose={props.handleOpenCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: 'flex', justifyContent: 'center', top: '250px' }}
    >
      <Box className={styles.container}>
        <Box className={styles.header}>
          Checkout <CloseIcon className={styles.closeIcon} onClick={handleCloseCheckout} />
        </Box>
        <Box className={styles.content}>
          <Box className={styles.row}>
            <span className={styles.rowTitle}>Item</span>
            <span>Price</span>
          </Box>
          <Divider />
          <Box className={styles.row} style={{ lineHeight: '70px' }}>
            <img className={styles.img} src={`${Monkey}`} alt="" />
            <span className={styles.rowTitle + ' ' + styles.boxName}>Box #12335</span>
            <span className={styles.value}>0.00555345 USDC</span>
          </Box>
          <Divider />
          <Box className={styles.row}>
            <span className={styles.rowTitle}>Total</span>
            <span className={styles.boxName + ' ' + styles.value}>0.00555345 USDC</span>
          </Box>
          <form className={styles.capcha} onSubmit={() => {}}>
            <ReCAPTCHA sitekey="6LczTJYdAAAAADHIu5M65j7VW2Kei4-ZgE8Fjo4Q" onChange={onChange} />
          </form>
        </Box>
        <Box className={styles.btnContainer}>
          <ColorButton disabled={isRobot} onClick={handleCheckout}>
            Checkout
          </ColorButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalCheckout;
