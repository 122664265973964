import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CampaignsImg from '../../assets/fakeImages/Rectangle28.png';
import artistAvatar from '../../assets/images/artistAvatar.svg';
import { ReactComponent as Shape } from '../../assets/images/shape.svg';
import DropItemCard from '../../components/card/Card';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    minHeight: '1412px',
  },
  backGround: {
    width: '100%',
    height: '360px',
    objectFit: 'cover',
  },
  container: {
    display: 'flex',
    marginTop: '40px',
  },
  left: {
    // width: '380px',
    position: 'relative',
    flexBasis: '27%',
  },
  card: {
    position: 'absolute',
    top: '-180px',
    left: '32px',
    width: '308px',
    height: '520px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05);',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    padding: '40px 54px 0px 54px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    border: '3px solid #EBECF0',
    boxSizing: 'border-box',
    borderRadius: '50%',
  },
  name: {
    fontSize: '24px;',
    lineHeight: '32px',
    margin: '20px 0px 8px 0px',
    color: ' #091342',
  },
  code: {
    background: ' #F4F5F7;',
    borderRadius: ' 30px',
    padding: '4px 10px',
    fontSize: '14px;',
    lineHeight: '16px',
    marginBottom: '28px',
  },
  description: {
    textAlign: 'center',
    fontSize: '14px;',
    lineHeight: '20px',
    color: '#42496E',
  },
  right: {
    flexGrow: '1',
    flexBasis: '73%',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  typeBtnWapper: {
    padding: '4px !important',
    borderRadius: '50px!important',
    '&.Mui-selected': {
      '& span:first-child': {
        color: ' #FFFFFF',
        backgroundColor: '#201934',
      },
    },
  },
  typeBtn: {
    background: '#FFFFFF',
    border: '1px solid #C1C4D0',
    minWidth: '84px',
    boxSizing: 'border-box',
    borderRadius: '50px',
    height: '40px',
    lineHeight: '23px',
    fontSize: '14px',
    padding: '8px 16px',
    textTransform: 'none',
  },
  typeBtnSelect: {
    padding: '0px ',
    borderRadius: '50px!important',
    height: '40px',
    minWidth: '125px',
    fontSize: '14px!important',
  },
  tagContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  pagination: {
    display: 'flex',
    '& .Mui-selected': {
      backgroundColor: '#252E58!important',
      color: '#fff',
      borderRadius: '3px;',
    },
    marginBottom: '80px',
  },
  hoverDiv: {
    width: '100%',
    background: '#DFE0E6',
    marginTop: '39px!important',
    padding: '16px',
  },
  active: {
    outline: 'rgb(255, 209, 72) auto 1px',
  },
}));
function ProfilePage(props: any) {
  const styles = useStyles(props);
  const [value, setValue] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  //fakedata
  const [arr, setArr] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [price, setPrice] = React.useState('10');
  const handleChangeType = (event: SelectChangeEvent) => {
    setPrice(event.target.value as string);
  };
  //
  const [keyActive, setKeyActive] = React.useState(null);
  const [index, setIndex] = React.useState(-1);
  const handleShowListCard = (idx: number, arr: any, key: any) => {
    // set active class
    setKeyActive(key);
    //reset arr without hover div
    arr = arr.filter((item: any) => item !== 'hover');
    // set index on click
    let newIndex = arr.findIndex((item: any) => item === key);
    setIndex(newIndex);
    //add hover div
    if (idx !== index) {
      for (let i = 1; i <= arr.length / 4; i++) {
        if ((idx + 1) / 4 <= i) {
          arr.splice(i * 4, 0, 'hover');
          break;
        }
      }
    }
    setArr([...arr]);
    // close div hover if click on same index
    if (!arr.find((item: any) => item === 'hover')) {
      setIndex(-1);
      setKeyActive(null);
    }
  };
  return (
    <Box className={styles.root}>
      <img className={styles.backGround} src={`${CampaignsImg}`} alt="" />
      <Box className={styles.container}>
        <Box className={styles.left}>
          <Box className={styles.card}>
            <img className={styles.avatar} src={`${artistAvatar}`} alt="" />
            <p className={styles.name}>Mike Mular</p>
            <Box className={styles.code}>
              0x2a0d2cdf...8b6f3bedbb <Shape />
            </Box>
            <Box className={styles.description}>
              Hey! I'm Vedad , 20 years old and I am a variety Streamer from Bosnia! Around here we just aim to have
              lovely time together and c...
            </Box>
          </Box>
        </Box>
        <Box className={styles.right}>
          <TabContext value={value}>
            <Box className={styles.tagContainer}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab className={styles.typeBtnWapper} label={<span className={styles.typeBtn}>Boxes</span>} value="1" />
                <Tab className={styles.typeBtnWapper} label={<span className={styles.typeBtn}>Items</span>} value="2" />
                <Tab
                  className={styles.typeBtnWapper}
                  label={<span className={styles.typeBtn}>On sale</span>}
                  value="3"
                />
                <Tab
                  className={styles.typeBtnWapper}
                  label={<span className={styles.typeBtn}>Opened</span>}
                  value="4"
                />
              </TabList>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl className={styles.typeBtnWapper}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={price}
                    onChange={handleChangeType}
                    IconComponent={KeyboardArrowDownIcon}
                    className={styles.typeBtnSelect}
                  >
                    <MenuItem value={10}>All</MenuItem>
                    <MenuItem value={20}>USD</MenuItem>
                    <MenuItem value={30}>VND</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={styles.typeBtnWapper}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={price}
                    onChange={handleChangeType}
                    IconComponent={KeyboardArrowDownIcon}
                    className={styles.typeBtnSelect}
                    style={{ marginLeft: '8px!important' }}
                  >
                    <MenuItem value={10}>Recently added</MenuItem>
                    <MenuItem value={20}>USD</MenuItem>
                    <MenuItem value={30}>VND</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <TabPanel value={value} style={{ padding: '0px' }}>
              <Grid container spacing={2}>
                {arr.map((item: any, idx: number) =>
                  item !== 'hover' ? (
                    <Grid
                      key={item}
                      item
                      xs={6}
                      md={3}
                      // className={index - 1 === idx ? styles.active : ''}
                      onClick={() => handleShowListCard(idx, arr, item)}
                    >
                      <DropItemCard active={keyActive === item ? styles.active : ''} type={'Legendary'} id={idx} />
                    </Grid>
                  ) : (
                    <Grid item className={styles.hoverDiv}>
                      <Grid container spacing={2}>
                        {[1, 2, 3, 4].map((item: any, index: number) => (
                          <Grid key={item} item xs={6} md={3}>
                            <DropItemCard type={'Legendary'} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ),
                )}
              </Grid>
            </TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
          </TabContext>
          <Box className={styles.pagination}>
            <Stack style={{ margin: '24px auto 0px' }} spacing={2}>
              <Pagination count={10} shape="rounded" />
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ProfilePage;
