import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import React from 'react';
import avatar from '../../assets/fakeImages/avatarCard.svg';
import Monkey from '../../assets/fakeImages/monkey.svg';
import Camera from '../../assets/images/camera.svg';
import CardDetailModel from './CardDetailModel';
import { ReactComponent as Poligon } from '../../assets/images/poligon.svg';
import { useNavigate } from 'react-router';
import { getBorderColorTypeCard, getColorTypeCard } from '../../utilities/helper';

const useStyles = makeStyles(theme => ({
  card: {
    marginRight: '20px',
    width: '242px',
    height: '391px',
    boxSizing: 'border-box',
    boxShadow: ' 0px 6px 20px rgba(32, 44, 55, 0.15)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    border: '1px solid #C1C4D0',
    display: 'flex',
    position: 'relative',
  },
  cardImg: {
    borderRadius: '8px;',
    boxSizing: 'border-box',
    boxShadow: '0px 10px 10px -10px rgba(0, 39, 85, 0.5), 0px 6px 20px rgba(0, 39, 85, 0.35);',
    width: '218px',
    height: '215px',
    objectFit: 'cover',
  },
  camera: {
    position: 'absolute',
    left: '5.64%;',
    right: '76.01%;',
    top: '4.99%;',
    bottom: '83.85%;',
    cursor: 'pointer',
  },
  typeCard: {
    border: (props: any) => getBorderColorTypeCard(props.type),
    color: (props: any) => getColorTypeCard(props.type),
    borderRadius: '18px;',
    padding: '2px 10px;',
    boxSizing: 'border-box',
    fontSize: '12px',
  },
  numberCard: {
    borderRadius: '18px;',
    border: '1px solid #DFE0E6',
    color: '#5E6484',
    background: '#F4F5F7;',
    fontSize: '12px',
    padding: '2px 10px',
  },
  nameCard: {
    fontSize: '14px;',
    lineHeight: ' 20px;',
    color: ' #42496E;',
    margin: '0px',
    cursor: 'pointer',
  },
  avatar: {
    width: '16px',
    height: '16px',
    border: '0.5px solid #E3E3E3',
    boxSizing: 'border-box',
    borderRadius: '50%',
    marginRight: '4px',
  },
  name: {
    fontSize: '12px',
    lineHeight: '16px ',
    color: '#7A7F9A',
    margin: '0px;',
  },
  namePrice: {
    flexGrow: '1',
    fontSize: '14px',
    lineHeight: '16px ',
    color: '#7A7F9A',
  },
  valuePrice: {
    fontSize: '16px',
    lineHeight: '24px ',
    color: '#C9A138',
  },
  poligon: {
    position: 'absolute',
    left: '45%;',
    bottom: '-42px;',
  },
}));
function Card(props: any) {
  const styles = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const handleOpenClose = () => setOpen(!open);
  const history = useNavigate();
  return (
    <Box className={styles.card + ' ' + props.active}>
      <Box style={{ margin: '0 auto' }}>
        <Box style={{ display: 'flex', marginTop: '16px' }}>
          <Box style={{ flexGrow: '1' }}>
            <span className={styles.typeCard}>Legendary</span>
          </Box>
          <span className={styles.numberCard}>10 editions</span>
        </Box>
        <Box style={{ display: 'flex', margin: '18px auto 16px auto', position: 'relative' }}>
          <img className={styles.cardImg} src={`${Monkey}`} alt="" />
          <Box>
            <img className={styles.camera} src={`${Camera}`} alt="" onClick={handleOpenClose} />
          </Box>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ flexGrow: '1' }}>
            <p className={styles.nameCard} onClick={() => history(`/BoxDetail/${props.id}`)}>
              Bored Aeps
            </p>
          </Box>
          <span className={styles.numberCard}>10 editions</span>
        </Box>
        <Box style={{ display: 'flex', marginBottom: '12px' }}>
          <img className={styles.avatar} src={`${avatar}`} alt="" />
          <p className={styles.name}>Owner name</p>
        </Box>
        <Divider />
        <Box style={{ display: 'flex', marginTop: '12px' }}>
          <span className={styles.namePrice}>Price</span>
          <span className={styles.valuePrice}>0.0669 USDC</span>
        </Box>
      </Box>
      <CardDetailModel open={open} handleOpenClose={handleOpenClose} type={props.type} />
      {props.active && <Poligon className={styles.poligon} />}
    </Box>
  );
}

export default Card;
