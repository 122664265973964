import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from 'react';
import { makeStyles } from '@mui/styles';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(theme => ({
  myAlert: {
    backgroundColor: '#00875A!important',
  },
}));

function SnapBar(props: any) {
  const styles = useStyles(props);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false);
  };
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        style={{ position: 'absolute', right: '0' }}
        open={props.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" />,
          }}
          className={styles.myAlert}
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          Successfully purchased!
        </Alert>
        {/* <Alert severity="error">This is an error message!</Alert> */}
      </Snackbar>
    </Stack>
  );
}

export default SnapBar;
