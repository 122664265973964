import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Campains from './Campains';
import LootBoxMarket from './LootBoxMarket';
import SlickBackground from './slickBackground';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FAFBFC',
  },
}));

function HomePage(props: any) {
  const styles = useStyles(props);
  return (
    <Box className={styles.root}>
      <SlickBackground />
      <LootBoxMarket />
      <Campains />
    </Box>
  );
}

export default HomePage;
