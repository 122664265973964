import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { getBorderColorTypeCard, getColorTypeCard } from '../../utilities/helper';

const useStyles = makeStyles(theme => ({
  tableBody: {
    background: (props: any) => props.backgroundColor,
    '& .MuiTableCell-root': {
      color: (props: any) => props.textColor,
      fontSize: '14px',
      padding: '15px 20px',
      borderBottom: (props: any) => (props.backgroundColor !== '#ffffff' ? '1px solid #5C5C5C' : ''),
    },
  },

  rarityName: {
    border: (props: any) => getBorderColorTypeCard(props.row.name),
    color: (props: any) => getColorTypeCard(props.row.name),
    boxSizing: 'border-box',
    borderRadius: '18px',
    padding: '3px 10px',
  },
}));

function SeriesContentTableData(props: {
  row: {
    name: string;
    Amount: number;
    TotalMinted: number;
    Probability: number;
  };
  backgroundColor: string;
  textColor: string;
}) {
  const styles = useStyles(props);
  return (
    <TableRow
      key={props.row.name}
      className={styles.tableBody}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <span className={styles.rarityName}>{props.row.name}</span>
      </TableCell>
      <TableCell align="right">{props.row.Amount}</TableCell>
      <TableCell align="right">{props.row.TotalMinted}</TableCell>
      <TableCell align="right">{props.row.Probability}%</TableCell>
    </TableRow>
  );
}

export default SeriesContentTableData;
