import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ReCAPTCHA from 'react-google-recaptcha';
import Monkey from '../../assets/images/TreasureBox.svg';

const useStyles = makeStyles(theme => ({
  container: {
    height: '530px',
    maxHeight: '854px',
    maxWidth: '1280px',
    width: '875px',
    backgroundColor: '#ffffff',
    position: 'relative',
    '&:focus-visible': {
      outline: 'none',
    },
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px;',
    padding: '60px',
    boxSizing: 'border-box',
  },
  image: {
    width: '410px',
    height: '410px',
  },
  capcha: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '39px',
  },
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '25px',
    color: ' #242424',
  },
  closeIcon: {
    width: '20px!important',
    height: '20px!important',
    cursor: 'pointer',
    color: '#949496',
  },
  note: {
    color: '#949496',
    '& span': {
      color: '#168df3',
    },
    marginBottom: '40px',
  },
  wapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '13px',
  },
  name: {
    color: '#949496',
    fontSize: '12px',
  },
  value: {
    color: '#000000',
    fontSize: '16px',
  },
}));

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#17204D',
  background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
  '&:hover': {
    background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
  },
  boxShadow: '0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31)',
  borderRadius: '4px',
  width: '100%',
  height: '40px',
  marginTop: '24px',
}));

function ModalCheckoutCampaign(props: any) {
  const styles = useStyles(props);
  const [isRobot, setIsRobot] = useState(true);
  function onChange(value: any) {
    setIsRobot(!isRobot);
  }
  const handleClose = () => {
    setIsRobot(true);
    props.handleOpenCloseSuccessModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleOpenClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: 'flex', justifyContent: 'center', top: '250px' }}
    >
      <Box className={styles.container}>
        <img className={styles.image} src={`${Monkey}`} alt="" />
        <Box>
          <Box className={styles.header}>
            Checkout <CloseIcon className={styles.closeIcon} onClick={props.handleOpenClose} />
          </Box>
          <Box className={styles.note}>
            You can buy a maximum of <span>10 boxes.</span>{' '}
          </Box>
          <Box className={styles.wapper}>
            <span className={styles.name}>Campaign</span>
            <span className={styles.value}>Fara Sporesian Loot Box</span>
          </Box>
          <Box className={styles.wapper}>
            <span className={styles.name}>Price per box</span>
            <span className={styles.value}>100 SPO</span>
          </Box>
          <Box className={styles.wapper}>
            <span className={styles.name}>Number of boxes</span>
            <span className={styles.value}>100 SPO</span>
          </Box>
          <Divider style={{ marginBottom: '11px' }} />
          <Box className={styles.wapper}>
            <span className={styles.name}>Total</span>
            <span className={styles.value} style={{ fontWeight: '500' }}>
              500 SPO
            </span>
          </Box>
          <form onSubmit={() => {}}>
            <ReCAPTCHA sitekey="6LczTJYdAAAAADHIu5M65j7VW2Kei4-ZgE8Fjo4Q" onChange={onChange} />
          </form>
          <ColorButton disabled={isRobot} onClick={handleClose}>
            Check out
          </ColorButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalCheckoutCampaign;
