import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { ReactComponent as LogoText } from '../../assets/images/logoTextFooter.svg';
import { ReactComponent as LogoImage } from '../../assets/images/LogoImageFooter.svg';
import { ReactComponent as Telegram } from '../../assets/images/socialIcon/telegram.svg';
import { ReactComponent as Twitter } from '../../assets/images/socialIcon/twitter.svg';
import { ReactComponent as Facebook } from '../../assets/images/socialIcon/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/images/socialIcon/instagram.svg';
import { ReactComponent as Youtube } from '../../assets/images/socialIcon/youtube.svg';
import { ReactComponent as Medium } from '../../assets/images/socialIcon/medium.svg';
import { ReactComponent as Linkin } from '../../assets/images/socialIcon/linkin.svg';
import { ReactComponent as Wechat } from '../../assets/images/socialIcon/wechat.svg';

const useStyles = makeStyles(theme => ({
  root: {
    height: '239px',
    background: '#CFB675',
    overflow: 'hidden',
    // fontFamily: 'Segoe UI Variable Static Text',
  },
  logoContainer: {
    position: 'relative',
    display: 'flex',
  },
  logoImage: {
    position: 'absolute',
    width: '229px',
    height: '161px',
    left: '-26px',
    top: '78px',
  },
  logoText: {
    marginLeft: '80px',
    marginTop: '32px',
    marginRight: '160px',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
  },
  title: {
    fontSize: '20px',
    color: '#242424',
    marginBottom: '16px',
    fontWeight: '400',
  },
  link: {
    fontSize: '14px',
    color: '#242424',
    marginBottom: '16px',
    textDecoration: 'none',
  },
  address: {
    display: 'flex',
    marginTop: '32px',
    flexGrow: '1;',
  },
  icon: {
    width: '24px',
    height: '24px',
    marginRight: '16px',
    marginBottom: '16px',
  },
}));

function Footer(props: any) {
  const styles = useStyles(props);
  return (
    <Box className={styles.root}>
      <Box className={styles.logoContainer}>
        <LogoText className={styles.logoText} />
        <Box className={styles.address}>
          <LogoImage className={styles.logoImage} />
          <Box className={styles.menu}>
            <span className={styles.title}>Menu</span>
            <span className={styles.link}>Loot Box</span>
            <span className={styles.link}>Marketplace</span>
            <span className={styles.link}>Launchpad</span>
            <span className={styles.link}>Staking</span>
          </Box>
          <Box className={styles.menu}>
            <span className={styles.title}>Community</span>
            <span className={styles.link}>Suggest Feature</span>
            <span className={styles.link}>Help Center</span>
          </Box>
          <Box className={styles.menu}>
            <span className={styles.title}>Legal</span>
            <span className={styles.link}>Terms Of Use</span>
            <span className={styles.link}>Privacy Policy</span>
            <span className={styles.link}>Cookie Statement</span>
          </Box>
          <Box className={styles.menu}>
            <span className={styles.title}>Contact</span>
            <Box>
              <Telegram className={styles.icon} />
              <Medium className={styles.icon} />
              <Linkin className={styles.icon} />
              <Twitter className={styles.icon} />
            </Box>
            <Box>
              <Youtube className={styles.icon} />
              <Facebook className={styles.icon} />
              <Instagram className={styles.icon} />
              <Wechat className={styles.icon} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
