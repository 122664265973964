import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import DropItemCard from '../../components/card/DropItemCard';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FAFBFC',
    minHeight: '1412px',
  },
  container: {
    margin: '75px 60px',
  },
  title: {
    fontSize: ' 29px',
    lineHeight: '40px',
    color: '#17204D',
    marginBottom: '20px',
  },
}));

function DropItem(props: any) {
  const styles = useStyles(props);
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  return (
    <Box className={styles.root}>
      <Box className={styles.container}>
        <p className={styles.title}>Drop items</p>
        <Box>
          <Grid container spacing={2}>
            {arr.map((item: any) => (
              <Grid key={item} item xs={4} md={2.4}>
                <DropItemCard type={'Epic'} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default DropItem;
