import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Monkey from '../../assets/fakeImages/monkey.svg';
import Camera from '../../assets/images/camera.svg';
import CardDetailModel from './CardDetailModel';
import { getBorderColorTypeCard, getColorTypeCard } from '../../utilities/helper';

const useStyles = makeStyles(theme => ({
  card: {
    marginRight: '20px',
    width: '242px',
    height: '323px',
    boxSizing: 'border-box',
    boxShadow: ' 0px 6px 20px rgba(32, 44, 55, 0.15)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    border: '1px solid #C1C4D0',
    display: 'flex',
  },
  cardImg: {
    borderRadius: '8px;',
    boxSizing: 'border-box',
    boxShadow: '0px 10px 10px -10px rgba(0, 39, 85, 0.5), 0px 6px 20px rgba(0, 39, 85, 0.35);',
    width: '218px',
    height: '215px',
    objectFit: 'cover',
  },
  camera: {
    position: 'absolute',
    left: '5.64%;',
    right: '76.01%;',
    top: '4.99%;',
    bottom: '83.85%;',
    cursor: 'pointer',
  },
  typeCard: {
    border: (props: any) => getBorderColorTypeCard(props.type),
    color: (props: any) => getColorTypeCard(props.type),
    borderRadius: '18px;',
    padding: '2px 10px;',
    boxSizing: 'border-box',
    fontSize: '12px',
  },
  numberCard: {
    borderRadius: '18px;',
    border: '1px solid #DFE0E6',
    color: '#5E6484',
    background: '#F4F5F7;',
    fontSize: '12px',
    padding: '2px 10px',
  },
  nameCard: {
    fontSize: '14px;',
    lineHeight: ' 20px;',
    color: ' #42496E;',
    margin: '0px',
    cursor: 'pointer',
  },
}));

function DropItemCard(props: any) {
  const styles = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const handleOpenClose = () => setOpen(!open);

  return (
    <Box className={styles.card}>
      <Box style={{ margin: '0 auto' }}>
        <Box style={{ display: 'flex', marginTop: '16px' }}>
          <Box style={{ flexGrow: '1' }}>
            <span className={styles.typeCard}>{props.type}</span>
          </Box>
        </Box>
        <Box style={{ display: 'flex', margin: '18px auto 16px auto', position: 'relative' }}>
          <img className={styles.cardImg} src={`${Monkey}`} alt="" />
          <Box>
            <img className={styles.camera} src={`${Camera}`} alt="" onClick={handleOpenClose} />
          </Box>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ flexGrow: '1' }}>
            <p className={styles.nameCard}>Bored Aeps</p>
          </Box>
          <span className={styles.numberCard}>10 editions</span>
        </Box>
      </Box>
      <CardDetailModel open={open} handleOpenClose={handleOpenClose} type={props.type} />
    </Box>
  );
}

export default DropItemCard;
