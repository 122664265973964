import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Paper, Table, TableBody, TableCell } from '@mui/material';
import Tab from '@mui/material/Tab';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import React from 'react';
import SeriesContentTableData from '../campaignDetailPage/SeriesContentTableData';

const useStyles = makeStyles(theme => ({
  //bottom
  bottom: {
    margin: '20px 120px 84px 120px',
    minHeight: '508px',
    backgroundColor: '#Ffffff',
    borderRadius: '8px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05)',
    padding: '40px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabPanel-root': {
      padding: '40px 0px 0px 0px',
    },
  },
  //table
  table: {
    minWidth: '466px!important',
    borderRadius: '10px!important',
    boxShadow: 'none!important',
  },
  tableHead: {
    backgroundColor: '#F4F5F7',
    '& .MuiTableCell-root': {
      color: '#17204D',
      fontSize: '14px',
      padding: '10px 20px',
      borderBottom: 'none',
    },
    '& .MuiTableCell-root:first-child': {
      paddingRight: '100px',
    },
  },
  tableTotal: {
    fontSize: '16px;',
    backgroundColor: '#2B2B2B',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    '& .MuiTableCell-root': {
      color: '#FFD362',
      fontSize: '14px',
      padding: '10px 20px',
    },
  },
  typeBtnWapper: {
    padding: '0px 4px!important',
    borderRadius: '50px!important',
    '&.Mui-selected': {
      '& span:first-child': {
        color: ' #FFFFFF',
        backgroundColor: '#201934',
      },
    },
  },
  typeBtn: {
    background: '#FFFFFF',
    border: '1px solid #C1C4D0',
    boxSizing: 'border-box',
    borderRadius: '50px',
    height: '40px',
    lineHeight: '23px',
    fontSize: '14px',
    padding: '8px 16px',
    textTransform: 'none',
  },
}));

function createData(name: string, Amount: number, TotalMinted: number, Probability: number) {
  return { name, Amount, TotalMinted, Probability };
}

const rows = [
  createData('Legendary', 1, 16, 1.03),
  createData('Epic', 1, 352, 0.59),
  createData('Super Rare', 1, 848, 1.41),
  createData('Rare', 3, 10800, 18),
  createData('Common', 10, 984, 79.97),
];

function BoxPrivateContentBottom(props: any) {
  const styles = useStyles(props);
  const [value, setValue] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box className={styles.bottom}>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              className={styles.typeBtnWapper}
              label={<span className={styles.typeBtn}>Series Content</span>}
              value="1"
            />
            <Tab
              className={styles.typeBtnWapper}
              label={<span className={styles.typeBtn}>Drop Items</span>}
              value="2"
            />
            <Tab
              className={styles.typeBtnWapper}
              label={<span className={styles.typeBtn}>About Artist</span>}
              value="3"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TableContainer className={styles.table} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell>Rarity</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Total Minted</TableCell>
                  <TableCell align="right">Probability</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <SeriesContentTableData key={row.name} row={row} backgroundColor={'#ffffff'} textColor={'#42496E'} />
                ))}
                <TableRow className={styles.tableHead} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    Total
                  </TableCell>
                  <TableCell align="right">16</TableCell>
                  <TableCell align="right">59,998</TableCell>
                  <TableCell align="right">100%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
    </Box>
  );
}

export default BoxPrivateContentBottom;
