export const getColorTypeCard = (type: string) => {
  switch (type.toLowerCase()) {
    case 'common':
      return '#D8D8D8';
    case 'super rare':
      return '#61C4F7';
    case 'rare':
      return '#00E742';
    case 'epic':
      return '#FC51FF';
    case 'legendary':
      return '#FFD148';
    default:
      return '#D8D8D8';
  }
};

export const getBorderColorTypeCard = (type: string) => {
  switch (type.toLowerCase()) {
    case 'common':
      return '1px solid #D8D8D8';
    case 'super rare':
      return '1px solid #61C4F7';
    case 'rare':
      return '1px solid #00E742';
    case 'epic':
      return '1px solid #FC51FF';
    case 'legendary':
      return '1px solid #FFD148';
    default:
      return '1px solid #D8D8D8';
  }
};

export const getBoxShadowColorTypeCard = (type: string) => {
  switch (type.toLowerCase()) {
    case 'common':
      return '0px 0px 100px 0px rgba(255, 255, 255)';
    case 'super rare':
      return '0px 0px 100px 0px rgba(97, 196, 247)';
    case 'rare':
      return '0px 0px 100px 0px rgba(41, 171, 78)';
    case 'epic':
      return '0px 0px 100px 0px rgba(170, 105, 236)';
    case 'legendary':
      return '0px 0px 100px 0px rgba(255, 202, 66)';

    default:
      return '0px 0px 100px 0px rgba(255, 255, 255)';
  }
};

export const getBoxShadowBox1ColorTypeCard = (type: string) => {
  switch (type.toLowerCase()) {
    case 'common':
      return '0px 0px 100px 0px rgba(255, 255, 255, 0.1)';
    case 'super rare':
      return '0px 0px 100px 0px rgba(97, 196, 247, 0.1)';
    case 'rare':
      return '0px 0px 100px 0px rgba(41, 171, 78, 0.1)';
    case 'epic':
      return '0px 0px 100px 0px rgba(170, 105, 236, 0.1)';
    case 'legendary':
      return '0px 0px 100px 0px rgba(255, 202, 66, 0.1)';

    default:
      return '0px 0px 100px 0px rgba(255, 255, 255, 0.1)';
  }
};

export const getBoxShadowBox2ColorTypeCard = (type: string) => {
  switch (type.toLowerCase()) {
    case 'common':
      return '0px 0px 100px 0px rgba(255, 255, 255, 0.2)';
    case 'super rare':
      return '0px 0px 100px 0px rgba(97, 196, 247, 0.2)';
    case 'rare':
      return '0px 0px 100px 0px rgba(41, 171, 78, 0.2)';
    case 'epic':
      return '0px 0px 100px 0px rgba(170, 105, 236, 0.2)';
    case 'legendary':
      return '0px 0px 100px 0px rgba(255, 202, 66, 0.2)';
    default:
      return '0px 0px 100px 0px rgba(255, 255, 255, 0.2)';
  }
};

export const getBackgroundContainerColorTypeCard = (type: string) => {
  switch (type.toLowerCase()) {
    case 'common':
      return 'rgba(255, 255, 255, 0.4)';
    case 'super rare':
      return 'rgba(97, 196, 247, 0.4)';
    case 'rare':
      return 'rgba(41, 171, 78, 0.4)';
    case 'epic':
      return 'rgba(170, 105, 236, 0.4)';
    case 'legendary':
      return 'rgba(255, 202, 66, 0.4)';
    default:
      return 'rgba(255, 255, 255, 0.4)';
  }
};

export const getBackgroundBox3ColorTypeCard = (type: string) => {
  switch (type.toLowerCase()) {
    case 'common':
      return '#FFFFFF';
    case 'super rare':
      return '#61C4F7';
    case 'rare':
      return '#29AB4E';
    case 'epic':
      return '#AA69EC';
    case 'legendary':
      return '#FFCA42';

    default:
      return '#FFFFFF';
  }
};

export const getBackgroundModalColorTypeCard = (type: string) => {
  switch (type.toLowerCase()) {
    case 'common':
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), radial-gradient(178.04% 84.86% at 50% 100%, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)';
    case 'super rare':
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), radial-gradient(178.04% 84.86% at 50% 100%, rgba(97, 196, 247, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)';
    case 'rare':
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), radial-gradient(178.04% 84.86% at 50% 100%, rgba(41, 171, 78, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)';
    case 'epic':
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), radial-gradient(178.04% 84.86% at 50% 100%, rgba(86, 27, 211, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)';
    case 'legendary':
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), radial-gradient(178.04% 84.86% at 50% 100%, rgba(255, 202, 66, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)';

    default:
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), radial-gradient(178.04% 84.86% at 50% 100%, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)';
  }
};
