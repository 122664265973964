import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider, InputLabel, MenuItem, FormControl } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as Avartar } from '../../assets/images/avatar.svg';
import { NavLink } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    height: '60px',
    margin: '0 auto',
    display: 'flex',
    background: ' #FFFFFF',
  },

  logo: {
    margin: '10px 35px',
    display: 'flex',
  },

  linklist: {
    margin: '10px 35px',
    float: 'right',
    display: 'flex',
    marginLeft: 'auto',
  },

  link: {
    textDecoration: 'none',
    marginRight: '24px',
    color: '#7A7F9A',
    lineHeight: '40px',
    height: '40px',
    fontSize: '16px',
    '&.active': {
      color: '#17204D',
    },
  },

  selectDropdown: {
    marginLeft: '10px',
    height: '40px',
    lineHeight: '40px',
    marginRight: '16px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .demo-simple-select-label': {
      padding: '8px',
    },
    '& #demo-simple-select-label': {
      top: '-6px',
    },
    '& .MuiSelect-select': {
      padding: '8px',
    },
  },

  btn_Connect: {
    background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
    borderRadius: ' 4px ',
    order: '0;',
    flexGrow: ' 0;',
    margin: '0px 12px;',
    fontSize: '14px ',
    //fontFamily: 'Segoe UI Variable Static Text ',
    fontWeight: '400 ',
    lineHeight: '8px ',
    color: '#17204D',
    borderEndEndRadius: '4px',
    border: '1px solid #DEB856',
    padding: '8px 16px',
  },
  avatar: {
    marginRight: '40px',
    minWidth: '160px',
    Height: '100%',
    backgroundColor: '#F4F5F7',
    border: '1px solid #DFE0E6',
    boxSizing: 'border-box',
    borderRadius: '60px',
  },
  avatarContainer: {
    width: '100%',
    height: '100%',
    margin: '3px',
    display: 'flex',
  },
  avatarDetail: {
    display: 'flex;',
    flexDirection: 'column',
    marginLeft: '8px',
  },
  avatarName: {
    fontSize: '14px',
    color: '#17204D',
  },
  avatarCode: {
    fontSize: '12px',
    color: '#A5A9BA',
  },
}));

function Header(props: any) {
  const styles = useStyles(props);
  const [age, setAge] = React.useState('');
  const [isDetailBox, setIsDetailBox] = React.useState(false);
  const [isHome, setIsHome] = React.useState(true);
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  return (
    <Box className={styles.root}>
      <Box className={styles.logo} onClick={() => navigate('/')}>
        <Logo />
      </Box>
      <Box className={styles.linklist}>
        <NavLink to="/Explore" className={styles.link}>
          Explore
        </NavLink>
        <NavLink to="/LootBox" className={styles.link}>
          {' '}
          Loot Box{' '}
        </NavLink>
        <NavLink to="/Launchpad" className={styles.link}>
          {' '}
          Launchpad{' '}
        </NavLink>
        <NavLink to="/Staking" className={styles.link}>
          {' '}
          Staking{' '}
        </NavLink>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{ minWidth: 130 }} className={styles.selectDropdown}>
          <FormControl fullWidth style={{ border: 'none' }}>
            <InputLabel id="demo-simple-select-label">Community</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value={10}></MenuItem>
              <MenuItem value={20}></MenuItem>
              <MenuItem value={30}></MenuItem>
            </Select>
          </FormControl>
        </Box>
        <NavLink to="/Staking" className={styles.link}>
          {' '}
          About us{' '}
        </NavLink>
        {isDetailBox && <button className={styles.btn_Connect}>Connect wallet</button>}
        {isHome && (
          <Box className={styles.avatar}>
            <Box className={styles.avatarContainer} onClick={() => navigate('/MyProfile')}>
              <Avartar />
              <Box className={styles.avatarDetail}>
                <span className={styles.avatarName}>Mike Mular</span>
                <span className={styles.avatarCode}>0x2A0D2...3bEdbB</span>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Header;
