import { Box } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { default as React } from 'react';
import Avatar from '../../assets/fakeImages/avatarCard.svg';
import Monkey from '../../assets/fakeImages/monkey.svg';
import { ReactComponent as Shape } from '../../assets/images/shape.svg';
import SnapBar from '../../components/SnapBar';
import BoxPrivateContentBottom from './BoxPrivateContentBottom';
import CardPublicContentBottom from './CardPublicContentBottom';
import ModalCheckout from './Modal/ModalCheckout';
import ModalBoxOpened from './Modal/ModalBoxOpened';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FAFBFC',
    position: 'relative',
  },
  //top
  top: {
    margin: '40px 120px 0px 120px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content1: {
    display: 'flex',
    borderRadius: '8px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05)',
    width: '488px',
    height: '500px',
  },
  boxImage: {
    padding: '30px 30px',
    backgroundColor: '#Ffffff',
    borderRadius: '8px',
    boxSizing: 'border-box',
    '& img': {
      width: '430px',
      height: '440px',
      objectFit: 'cover',
    },
  },
  content2: {
    width: '692px',
    height: '500px',
    backgroundColor: '#Ffffff',
    borderRadius: '8px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05)',
  },
  wapper: {
    margin: '40px 24px 0px 28px',
    //fontFamily: '"Segoe", "UI", "Variable", "Static", "Text"',
    fontStyle: 'normal;',
  },
  title: {
    marginBottom: '28px',
    fontWeight: '400',
    fontSize: '36px',
    lineHeight: '44px',
    color: '#17204D',
  },
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#17204D',
  },
  descriptionContent: {
    paddingRight: '12px',
    height: '177px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px;',
      border: '1px solid #E2E8F0;',
      backgroundColor: '#E2E8F0;',
      borderRadius: '100px;',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      background: '#000;',
      borderRadius: '10px;',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4A5568;',
      height: '40px;',
      borderRadius: '100px;',
    },
    '& div': {
      display: 'flex',
      '& p': {
        margin: '0px 0px',
        lineHeight: '24px',
        fontSize: '14px',
        '& span': {
          fontWeight: 'bold',
        },
        color: '#17204D',
      },
      border: '1px solid #C1C4D0',
      boxSizing: 'border-box',
      borderRadius: '40px',
      padding: '10px 10px',
      margin: '0px 0px 12px 0px',
    },
  },
  avatar: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
    marginRight: '10px',
  },
  author: {
    flexGrow: '1',
  },
  code: {
    color: '#6554C0!important',
    cursor: 'pointer',
  },
  bottomContainer: {
    display: 'flex',
    marginTop: '87px',
  },
  priceLable: {
    margin: '0px',
    fontSize: '14px',
  },
  priceValue: {
    margin: '0px',
    color: '#C8AA5D',
    fontSize: '24px',
  },
  buyBtn: {
    marginRight: '12px',
    width: '245px;',
    height: '48px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05)',
    background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
  },
  //bottom
  bottom: {
    margin: '20px 120px 84px 120px',
    minHeight: '508px',
    backgroundColor: '#Ffffff',
    borderRadius: '8px',
    boxShadow: '0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05)',
    padding: '40px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabPanel-root': {
      padding: '40px 0px 0px 0px',
    },
  },
  //table
  table: {
    minWidth: '466px!important',
    borderRadius: '10px!important',
    boxShadow: 'none!important',
  },
  tableHead: {
    backgroundColor: '#F4F5F7',
    '& .MuiTableCell-root': {
      color: '#17204D',
      fontSize: '14px',
      padding: '10px 20px',
      borderBottom: 'none',
    },
    '& .MuiTableCell-root:first-child': {
      paddingRight: '100px',
    },
  },
  tableTotal: {
    fontSize: '16px;',
    backgroundColor: '#2B2B2B',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    '& .MuiTableCell-root': {
      color: '#FFD362',
      fontSize: '14px',
      padding: '10px 20px',
    },
  },
  typeBtnWapper: {
    padding: '0px 4px!important',
    borderRadius: '50px!important',
    '&.Mui-selected': {
      '& button': {
        color: ' #FFFFFF',
        backgroundColor: '#201934',
      },
    },
  },
  typeBtn: {
    background: '#FFFFFF',
    border: '1px solid #C1C4D0',
    boxSizing: 'border-box',
    borderRadius: '50px',
    height: '40px',
    fontSize: '14px',
    padding: '8px 16px',
  },
}));

const ColorButtonDEB856 = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#17204D',
  minWidth: '245px',
  minHeight: '48px',
  fontSize: '16px',
  background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
  '&:hover': {
    background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
  },
  boxShadow: '0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31)',
  height: '40px',
  fontFamily: 'inherit',
}));

const ColorButtonEBECF0 = styled(ColorButtonDEB856)<ButtonProps>(({ theme }) => ({
  marginRight: '12px',
  background: '#EBECF0',
  '&:hover': {
    background: '#EBECF0',
  },
}));

function BoxDetailPage(props: any) {
  const styles = useStyles(props);
  const [showModal, setShowModal] = React.useState(false);
  const handleOpenCloseModal = () => {
    setShowModal(!showModal);
  };
  const [showModalOpenedBox, setShowModalOpenedBox] = React.useState(false);
  const handleOpenCloseModalBoxOpened = () => {
    setShowModalOpenedBox(!showModalOpenedBox);
  };
  const [isCheckout, setIsCheckout] = React.useState(false);
  const handleClick = () => {
    setIsCheckout(!isCheckout);
    setShowModal(!showModal);
  };
  return (
    <Box className={styles.root}>
      <Box className={styles.top}>
        <Box className={styles.content1}>
          <Box className={styles.boxImage}>
            <img src={`${Monkey}`} alt="" />
          </Box>
        </Box>
        <Box className={styles.content2}>
          <Box className={styles.wapper}>
            <p className={styles.title}>Bored Aeps Amet minim mollit</p>
            <p className={styles.description}>Description</p>
            <Box className={styles.descriptionContent}>
              <Box>
                <img className={styles.avatar} src={`${Avatar}`} alt="" />
                <p className={styles.author}>
                  Owned by <span>@creatorname</span>
                </p>
                <p className={styles.code}>
                  0x2a0d2cdf...8b6f3bedbb <Shape />
                </p>
              </Box>
              <span style={{ color: '#7A7F9A' }}>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis
                enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation
                veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis
                enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation
                veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis
                enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation
                veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis
                enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation
                veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              </span>
            </Box>
            {/* <Box className={styles.bottomContainer}>
              <Box style={{ flexGrow: '1' }}>
                <p className={styles.priceLable}>Price</p>
                <p className={styles.priceValue}>0.000005 USDC</p>
              </Box>
              <button className={styles.buyBtn} onClick={handleOpenCloseModal}>
                BUY NOW
              </button>
            </Box> */}
            <Box className={styles.bottomContainer} style={{ justifyContent: 'end', paddingRight: '12px' }}>
              <ColorButtonEBECF0>Put on sale</ColorButtonEBECF0>
              <ColorButtonDEB856 onClick={handleOpenCloseModalBoxOpened}>Open box</ColorButtonDEB856>
            </Box>
          </Box>
        </Box>
      </Box>
      <BoxPrivateContentBottom />
      <CardPublicContentBottom />
      <ModalCheckout open={showModal} handleOpenCloseModal={handleOpenCloseModal} handleClick={handleClick} />
      <SnapBar open={isCheckout} setOpen={setIsCheckout} />
      <ModalBoxOpened open={showModalOpenedBox} handleOpenCloseModal={handleOpenCloseModalBoxOpened} />
    </Box>
  );
}

export default BoxDetailPage;
