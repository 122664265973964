import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import CampaignsImg from '../../assets/fakeImages/Rectangle28.png';

const useStyles = makeStyles(theme => ({
  campaignsContainer: {
    marginTop: '60px',
    marginLeft: '32px',
  },
  campaignsTiltle: {
    fontSize: '29px',
    fontWeight: '400',
    lỉneHeight: '40px',
    marginTop: '0 0 20px 0',
  },
  campaignsDetail: {
    display: 'flex',
    width: '100%',
    paddingBottom: '100px',
  },
  CampaignsImg1: {
    backgroundImage: `url(${CampaignsImg})`,
    width: '794px',
    height: '300px',
  },
  CampaignsImg2: {
    backgroundImage: `url(${CampaignsImg})`,
    marginLeft: '20px',
    width: '562px',
    height: '300px',
  },
  CampaignsImgSession: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CampaignsText: {
    fontSize: '24px',
    lineHeight: '32px',
    color: '#FFFFFF',
    alignItems: 'center',
  },
  CampaignsBtn: {
    background: 'rgba(255, 255, 255, 0.2)',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '40px',
    color: '#FFFFFF',
    fontSize: '18px',
    padding: '10px 30px',
    alignItems: 'center',
  },
  wapper: {
    marginLeft: '32px',
  },
  title: {
    fontSize: '29px',
    lineHeight: '40px',
    margin: '0px 0px 20px',
    color: '#17204D',
  },
}));

function Campains(props: any) {
  const styles = useStyles(props);
  return (
    <Box className={styles.wapper}>
      <p className={styles.title}>CAMPAIGNS</p>
      <Box className={styles.campaignsDetail}>
        <Box className={styles.CampaignsImg1}>
          <Box className={styles.CampaignsImgSession}>
            <Box>
              <p className={styles.CampaignsText}>CrypToadz by GREMPLIN</p>
              <button className={styles.CampaignsBtn}>SOLD OUT</button>
            </Box>
          </Box>
        </Box>
        <Box className={styles.CampaignsImg2}>
          <Box className={styles.CampaignsImgSession}>
            <Box style={{ marginLeft: '290px' }}>
              <p className={styles.CampaignsText}>CryptoPunks</p>
              <button className={styles.CampaignsBtn}>SOLD OUT</button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Campains;
