import { Box, Modal } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Success from '../../assets/images/success.svg';

const useStyles = makeStyles(theme => ({
  container: {
    height: '281px',
    width: '558px',
    backgroundColor: '#ffffff',
    position: 'relative',
    '&:focus-visible': {
      outline: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px;',
    padding: ' 32px 0px 0px 0px',
    boxSizing: 'border-box',
    boxShadow: '0px 23px 50px rgba(10, 31, 67, 0.2), 0px 12px 7px rgba(10, 31, 67, 0.05)',
  },
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '25px',
    color: ' #242424',
  },
  image: {
    width: '80px',
    height: '80px',
  },
  wapBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  title: {
    margin: '0px ',
    fontSize: '20px;',
    lineHeight: '28px;',
    color: '#17204D;',
  },
  content: {
    margin: '8px 0px',
    fontSize: '14px;',
    lineHeight: '21px;',
    color: '#585858',
    '& span': {
      color: '#DEB856',
    },
  },
}));

const ColorButtonDEB856 = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#17204D',
  background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
  '&:hover': {
    background: 'linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%)',
  },
  boxShadow: '0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31)',
  borderRadius: '8px',
  height: '40px',
  marginTop: '24px',
  textTransform: 'none',
}));

const ColorButtonEBECF0 = styled(ColorButtonDEB856)<ButtonProps>(({ theme }) => ({
  marginRight: '12px',
  background: '#EBECF0',
  '&:hover': {
    background: '#EBECF0',
  },
}));

function ModalBuyBoxSPOSuccess(props: any) {
  const styles = useStyles(props);
  return (
    <Modal
      open={props.open}
      onClose={props.handleOpenClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: 'flex', justifyContent: 'center', top: '250px' }}
    >
      <Box className={styles.container}>
        <img className={styles.image} src={`${Success}`} alt="" />
        <p className={styles.title}>Congratulations!</p>
        <p className={styles.content}>
          You have successfuly bought Loot Box <span> Bored Ape Yacht Club - Wave #1</span>
        </p>
        <Box className={styles.wapBtn}>
          <ColorButtonEBECF0>Go to Profile</ColorButtonEBECF0>
          <ColorButtonDEB856>Continue buying</ColorButtonDEB856>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalBuyBoxSPOSuccess;
