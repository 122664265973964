import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import artistAvatar from '../../assets/images/artistAvatar.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FAFBFC',
    minHeight: '571px',
    height: '571px',
    margin: '75px 60px 169px 60px',
  },
  wapper: {
    padding: '40px',
    filter: 'drop-shadow(0px 3px 25px rgba(9, 30, 66, 0.09)) drop-shadow(0px 2px 5px rgba(9, 30, 66, 0.05))',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    minHeight: '571px',
    height: '571px',
    boxSizing: 'border-box',
    display: 'flex',
  },
  left: {
    marginRight: '60px',
  },
  img: {
    width: '664px',
    height: '491px',
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    paddingBottom: '16px',
    borderBottom: '1px solid #5E6484',
  },
  name: {
    fontSize: '29px',
    lineHeight: '40px',
    textTransform: 'uppercase',
    color: '#17204D',
    margin: '20px 0 8px 0',
  },
  content: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#7A7F9A',
  },
}));
function AboutArtist(props: any) {
  const styles = useStyles(props);
  return (
    <Box className={styles.root}>
      <Box className={styles.wapper}>
        <Box className={styles.left}>
          <img className={styles.img} src={`${artistAvatar}`} alt="" />
        </Box>
        <Box className={styles.right}>
          <Box style={{ marginBottom: '16px' }}>
            <span className={styles.title}>About Artist</span>
          </Box>
          <p className={styles.name}>Mike Mular</p>
          <span className={styles.content}>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est
            sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat
            sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
            consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
          </span>
        </Box>
      </Box>
    </Box>
  );
}

export default AboutArtist;
